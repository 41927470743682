import React, { lazy, useEffect, useContext } from 'react';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect
} from 'react-router-dom';
import CryptoJS from 'crypto-js';
import useHotjar from 'react-use-hotjar';

import { getSignInPath } from 'utils/login';
import { getSignUpPath } from 'utils/signup';
import { logout, retry } from 'utils';
import { getUserInfoToken } from 'services/auth';
import {
  BETA_URL_HOSTNAME,
  BETA_URL_REDIRECT,
  PRODUCTION_URL,
  PRODUCTION_URL_HOSTNAME,
  STAGING_URL_HOSTNAME
} from 'constants/login';
import { ContextWrapper } from 'contexts/wrapper.context';

import routes from 'routes';
import { initializeFirebase } from 'Firebase/push-notification';
import { notify } from 'components/Notify/Notify';
import InitialLoad from 'components/InitialLoad/InitialLoad';
import { isAuthorized } from 'utils/teams-and-member';
import ForgetPassword from 'components/SignIn/components/ForgetPassword/ForgetPassword';
import ResetPassword from 'components/SignIn/components/ResetPassword/ResetPassword';
import EmailResetPassword from 'components/SignIn/components/EmailResetPassword/EmailResetPassword';
import VerifyEmail from 'components/SignUp/components/VerifyEmail/VerifyEmail';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import NotFoundPage from 'components/Settings/components/TeamsMembers/components/NotFoundPage/NotFoundPage';
import Page404 from 'components/Page404/Page404';
import Page500 from 'components/Page500/Page500';
import HotjarHoc from 'components/HotjarHoc/HotjarHoc';
import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import SetupGuideWrapper from 'components/SetupGuideWrapper/SetupGuideWrapper';
import SignUpRoute from 'components/SignUpRoute/SignUpRoute';

import BostaLogoGif from 'assets/bosta-icons/BostaLogoGif.gif';

import './styles/global-styles.less';

const InviteTeamsMember = lazy(() =>
  retry(() =>
    import(
      'components/Settings/components/TeamsMembers/components/SignUpInvitations/SignUpInvitations'
    )
  )
);
const ServiceUnAvailableInCountry = lazy(() =>
  retry(() =>
    import('components/ServiceUnAvailableInCountry/ServiceUnAvailableInCountry')
  )
);
const YearlyReport = lazy(() =>
  retry(() => import('components/YearlyReport/YearlyReport'))
);
const AppUpdates = lazy(() =>
  retry(() => import('components/AppUpdates/AppUpdates'))
);

const loading = () => (
  <LoadingWrapper
    className="app-loading-wrapper"
    indicator={<img src={BostaLogoGif} alt="" />}
  />
);

const DefaultLayout = lazy(() =>
  retry(() => import('components/DefaultLayout/DefaultLayout'))
);

const SignIn = lazy(() => retry(() => import('components/SignIn/SignIn')));

const BusinessInformation = lazy(() =>
  retry(() =>
    import(
      'components/SignUp/components/BusinessInformation/BusinessInformation'
    )
  )
);

const WelcomeSllr = lazy(() =>
  retry(() => import('components/WelcomeSllr/WelcomeSllr'))
);

const userInfo = () => JSON.parse(localStorage?.getItem('userInfo'))?.user;
const isPhoneVerified = () => userInfo()?.isPhoneVerified;
const isPlannedShipmentType = () => userInfo()?.plannedShipmentType;
const isFullyActivated = () => userInfo()?.isUserFullyActivated;
const isPlannedPaymentFrequency = () => userInfo()?.plannedPaymentFrequency;

const isAuth = () => localStorage.getItem('newToken');
const isBetaUser = () => userInfo()?.businessAdminInfo?.isBetaUser;
const isVerifyEmailRedirect = () => localStorage.getItem('tempVerifyToken');

function PrivateRoute({ component: Component, access, initialProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const redirectPath = {};
        if (props.location?.pathname) {
          redirectPath.pathName = props.location.pathname;
          redirectPath.pathSearch = props.location.search || '';
          redirectPath.pathState = props.location.state || undefined;
        }
        const isFromBeta = new URLSearchParams(props?.location?.search).get(
          'fromBeta'
        );
        if (isAuth() && !isFromBeta) {
          const userInfo = JSON.parse(localStorage.getItem('userInfo'));
          if (!userInfo?.user) {
            return (
              <Redirect
                to={{
                  pathname: '/logout'
                }}
              />
            );
          } else if (
            window.location.hostname === PRODUCTION_URL &&
            isBetaUser()
          ) {
            window.open(
              `${BETA_URL_REDIRECT}${userInfo.token.replace('Bearer ', '')}`,
              '_parent'
            );
          } else if (
            userInfo.user.businessAdminInfo?.businessId &&
            !userInfo.user.isPhoneVerified
          ) {
            return (
              <Redirect
                to={{
                  pathname: '/accept-invitation'
                }}
              />
            );
          } else if (
            (!isPhoneVerified() ||
              !isPlannedShipmentType() ||
              !isPlannedPaymentFrequency()) &&
            !isFullyActivated()
          ) {
            return (
              <Redirect
                to={{
                  pathname: getSignUpPath(),
                  state: {
                    phone: userInfo.user?.profile?.phone,
                    email: userInfo.user.emails[0].address,
                    userId: userInfo.user._id
                  }
                }}
              />
            );
          } else if (isAuth() && isVerifyEmailRedirect()) {
            return (window.location = `/verify-email/?token=${isVerifyEmailRedirect()}`);
          } else {
            if (
              userInfo.user.group &&
              !isAuthorized(userInfo.user.group.name, access)
            ) {
              return <Redirect to={{ pathname: '/' }} />;
            }
            return (
              <HotjarHoc>
                <DefaultLayout {...props}>
                  <SetupGuideWrapper>
                    <Component {...props} {...initialProps} />
                  </SetupGuideWrapper>
                </DefaultLayout>
              </HotjarHoc>
            );
          }
        } else {
          const {
            location: { search, pathname },
            history
          } = props;
          const token = new URLSearchParams(search).get('token');
          if (
            [
              BETA_URL_HOSTNAME,
              PRODUCTION_URL_HOSTNAME,
              STAGING_URL_HOSTNAME
            ].includes(window?.location?.hostname) &&
            token
          ) {
            getUserInfoToken(token)
              .then((res) => {
                localStorage.setItem('userInfo', JSON.stringify(res));
                localStorage.setItem('newToken', res.token);
                window.history.replaceState(
                  null,
                  null,
                  pathname ? pathname : '/orders'
                );
                window.location.reload();
              })
              .catch((err) => {
                history.push(getSignInPath());
              });
          } else {
            return (
              <Redirect
                to={{
                  pathname: getSignInPath(),
                  state: redirectPath
                }}
              />
            );
          }
        }
      }}
    />
  );
}
function App() {
  const { businessInfo, isFetchingData } = useContext(ContextWrapper);

  useEffect(() => {
    if (isAuth()) {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      window.freshDeskSettings = {
        firstName: `${userInfo?.user?.profile?.firstName}`,
        lastName: `${userInfo?.user?.profile?.lastName}`,
        email: userInfo?.user?.emails[0]?.address,
        // created_at: '1312182000',
        user_id: userInfo?.user?._id || '',
        user_hash: CryptoJS.HmacSHA256(
          userInfo?._id,
          'NyTLQ7kKJU06HvrRwltPU83y3BHp0j7AEEtRjJ9b'
        ).toString(CryptoJS.enc.Hex),
        businessName: `${userInfo?.user?.businessAdminInfo?.businessName}`,
        countryName: userInfo?.user?.country?.name,
        dashboard: 'Bosta Dashboard'
      };
    } else {
      window.freshDeskSettings = {};
    }
    initializeFirebase();
  }, []);

  const { initHotjar } = useHotjar();

  useEffect(() => {
    if (window.env?.HOTJAR_API_KEY) {
      initHotjar(window.env?.HOTJAR_API_KEY, 6, false);
    }
  }, [initHotjar]);

  useEffect(() => {
    if (window.location.href.includes('/verify-email')) {
      const token = new URLSearchParams(window.location.search).get('token');
      localStorage.setItem('tempVerifyToken', token);
    }
  }, []);

  return isFetchingData && isAuth() ? (
    loading()
  ) : (
    <Router>
      {isAuth() && <InitialLoad />}
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route
            path="/"
            basename="/"
            exact
            name="Home"
            render={() =>
              isAuth() ? (
                <Redirect
                  to={{
                    pathname: '/overview',
                    state: {
                      activationModal:
                        !userInfo()?.isUserFullyActivated &&
                        !businessInfo.isRegisteredViaBetaFunnel
                    }
                  }}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: getSignInPath()
                  }}
                />
              )
            }
          />
          {routes(businessInfo).map((route, idx) => {
            return route.component ? (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                access={route.access}
                private={route.private}
                render={(props) => {
                  let isComponentExperimentalActive = false;
                  if (route.isExperimental) {
                    let experiments = [];
                    try {
                      experiments =
                        JSON.parse(
                          localStorage.getItem(route.localStorageKey)
                        ) || [];
                    } catch (error) {
                      notify({ msg: error.message, error });
                    }
                    isComponentExperimentalActive =
                      experiments.find(
                        (experiment) => experiment.id === route.experimentId
                      )?.isActive || false;
                  }
                  const ExperimentalComponent = route.isExperimental
                    ? route.component(isComponentExperimentalActive)
                    : route.component;
                  return route.private === false ? (
                    <ExperimentalComponent {...props} />
                  ) : (
                    <PrivateRoute
                      component={ExperimentalComponent}
                      access={route.access}
                      initialProps={route.initialProps}
                    />
                  );
                }}
              />
            ) : null;
          })}
          <Route
            exact
            path={['/signin/:locale?']}
            name="Login Page"
            render={(extraProps) => {
              return isAuth() ? (
                <Redirect
                  to={
                    extraProps.location.search === '?payment'
                      ? '/settings/payment-info'
                      : '/'
                  }
                />
              ) : (
                <SignIn />
              );
            }}
          />
          <Route
            exact
            path={['/signup']}
            name="Register Page"
            render={(extraProps) =>
              (isAuth() &&
                isPhoneVerified() &&
                isPlannedShipmentType() &&
                isPlannedPaymentFrequency()) ||
              isFullyActivated() ? (
                <Redirect to="/" />
              ) : (
                <SignUpRoute {...extraProps} />
              )
            }
          />
          <Route
            exact
            path="/verify-email"
            name="Register Page"
            render={(extraProps) =>
              // (isAuth() && extraProps?.location?.state?.email) ||
              extraProps?.location?.search ? (
                <HotjarHoc>
                  <VerifyEmail {...extraProps} />
                </HotjarHoc>
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/business-information"
            name="Register Page"
            render={(extraProps) =>
              isAuth() && isPhoneVerified() ? (
                <BusinessInformation {...extraProps} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/welcome-sllr"
            name="Welcome Sllr"
            render={(extraProps) =>
              isAuth() ? <WelcomeSllr {...extraProps} /> : <Redirect to="/" />
            }
          />
          <Route
            exact
            path="/forget-password/"
            name="Forget Password Page"
            render={() => (isAuth() ? <Redirect to="/" /> : <ForgetPassword />)}
          />
          <Route
            exact
            path="/reset/:token"
            name="Reset Password Page"
            render={() => {
              if (isAuth()) {
                logout();
              }
              return <ResetPassword />;
            }}
          />
          <Route
            exact
            path="/confirm-reset-password"
            name="Forget Password Page"
            render={(extraProps) =>
              isAuth() ? (
                <Redirect to="/" />
              ) : (
                <EmailResetPassword {...extraProps} />
              )
            }
          />
          <Route
            exact
            path="/set-new-password"
            render={() =>
              isAuth() ? <Redirect to="/" /> : <ResetPassword passwordExpired />
            }
          />
          <Route
            exact
            path="/accept-invitation"
            name=""
            render={(extraProps) => {
              return <InviteTeamsMember {...extraProps} />;
            }}
          />
          <Route
            exact
            path="/yearly-report"
            name=""
            render={(extraProps) => {
              return isAuth() ? (
                <YearlyReport {...extraProps} />
              ) : (
                <Redirect
                  to={{
                    pathname: '/signin',
                    state: { pathName: '/yearly-report' }
                  }}
                />
              );
            }}
          />
          <Route
            exact
            path="/NotFound"
            name="NotFound"
            render={(extraProps) => <NotFoundPage {...extraProps} />}
          />
          <Route
            exact
            path="/_loading"
            name="Loading"
            render={(extraProps) => <LoadingPage {...extraProps} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(extraProps) => <Page500 {...extraProps} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(extraProps) => <Page404 {...extraProps} />}
          />
          <Route
            exact
            path="/unavailable-country"
            name="Country Unavailable"
            render={(extraProps) => (
              <ServiceUnAvailableInCountry {...extraProps} />
            )}
          />
          <Route
            exact
            path="/logout"
            name="Logout"
            render={() => {
              if (isAuth()) return logout();
              else return <Redirect to="/" />;
            }}
          />
          <Route
            exact
            path="/app-updates"
            name="AppUpdates"
            render={(extraProps) => <AppUpdates {...extraProps} />}
          />
          <Route
            path="*"
            render={(extraProps) => <Page404 {...extraProps} />}
          />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
