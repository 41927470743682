import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Modal } from 'antd';
import classnames from 'classnames';

import BRButton from 'components/BRButton/BRButton';

import './ActivationModal.less';

const ActivationModal = ({
  intl,
  close,
  icon,
  title,
  subtitle,
  buttonText,
  buttonProps,
  onOk,
  cancelText,
  shouldGoBackToSLLR = false,
  onCancel,
  hideCancelButton = false,
  wrapClassName = '',
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleActivateNow = () => {
    shouldGoBackToSLLR && localStorage.setItem('shouldGoBackToSLLR', true);
    window.location.replace('/business-information');
  };

  const handleCloseModal = () => {
    if (onCancel) {
      onCancel();
    }
    close();
  };

  const handleOk = async () => {
    if (onOk) {
      setIsLoading(true);
      await onOk();
      setIsLoading(false);
      close();
    } else {
      handleActivateNow();
    }
  };

  return (
    <Modal
      wrapClassName={classnames('br-activation-modal', wrapClassName)}
      footer={null}
      {...props}
    >
      <div
        className={classnames('br-activation-modal__header', {
          'br-activation-modal__header-small': !title
        })}
      >
        {icon}
        <div className="br-activation-modal__content">
          <span className="br-activation-modal__title display-sm">{title}</span>
          <span className="br-activation-modal__subtitle body">{subtitle}</span>
        </div>
      </div>
      <div
        className={classnames('br-activation-modal__footer', {
          'br-activation-modal__footer-small': hideCancelButton
        })}
      >
        <BRButton
          type="primary"
          className="button"
          label={buttonText}
          onClick={handleOk}
          loading={isLoading}
          {...buttonProps}
        />
        {!hideCancelButton && (
          <BRButton
            type="link-gray"
            className="button"
            label={
              cancelText ||
              intl.formatMessage({
                id: 'sign_up.activation_modal.explore'
              })
            }
            onClick={handleCloseModal}
            disabled={isLoading}
          />
        )}
      </div>
    </Modal>
  );
};

export default injectIntl(ActivationModal);
