import XlsxPopulate from 'xlsx-populate';
import * as yup from 'yup';
import omitBy from 'lodash/omitBy';
import stringSimilarity from 'string-similarity';
import dayjs from 'dayjs';

import { fmt } from 'components/IntlWrapper/IntlWrapper';

import { getBooleanValue, getCODAmount } from 'utils/orders';
import { downloadAsXlsx, validatePhoneNumber } from 'utils';
import { sendSegment } from 'utils/segment';
import {
  ALLOWED_MULTI_PACKAGES_TYPES,
  CASH_COLLECTION,
  COD_MAX_LIMIT,
  CONSTRUCT_ORDERS_MAPPING,
  DELIVER,
  EXCEEDS_LIMIT,
  EXCHANGE,
  FULFILLMENT,
  FULFILLMENT_ORDERS,
  INTERNATIONAL_ORDERS,
  INTERNATIONAL_RETURN_LOCATION_MAPPING,
  NO_ORDERS,
  OLD_BOSTA_TEMPLATE,
  REGULAR,
  RETURN,
  SEND,
  SIGN_AND_RETURN,
  SMART_MASSUPLOAD_ARTICLE_LINK,
  UPLOAD_TYPE_COLUMNS_MAPPING
} from 'constants/smart-massupload';
import { COUNTRY_CURRENCY_MODIFIED, isSaudi } from 'constants/helpers';
import {
  FIRST_LINE_MIN_LENGTH,
  MULTI_PACKAGES_COUNT,
  PACKAGE_TYPES_OPTIONS,
  REFUND_MAX_AMOUNT
} from 'constants/shipments';
import {
  getUserCurrency,
  isMultiPackageAvailable
} from 'constants/countries/countries-mapping';
import { INCLUDES_DIGITS_REGEX } from 'constants/Forms';
import { checkProductAvailability } from 'services/fulfillment';
import { formatNumbersBasedOnLanguage } from './intl-wrapper';
import { addCountryCodeNumber, regexFractionsNumber } from './helpers';
import {
  CUSTOM_VAT_TYPES,
  CUSTOM_VAT_TYPES_OPTIONS
} from 'constants/international-orders';

import { notify } from 'components/Notify/Notify';

export const ORDER_TYPES = {
  deliver: 'SEND',
  cash_collection: 'CASH_COLLECTION',
  exchange: 'EXCHANGE',
  return: 'CUSTOMER_RETURN_PICKUP',
  sign_and_return: 'SIGN_AND_RETURN',
  fxf_send: 'FXF_SEND'
};

const getOrderType = (orderType) => {
  if (!orderType) {
    return undefined;
  } else if (
    !ORDER_TYPES[
      orderType?.toString()?.replace(/\s+/g, ' ')?.trim()?.toLowerCase()
    ]
  ) {
    return null;
  } else {
    return ORDER_TYPES[
      orderType?.toString()?.replace(/\s+/g, ' ')?.trim()?.toLowerCase()
    ];
  }
};

const getFirstLine = ({ streetName, area }) => {
  if (streetName && area) {
    return `${streetName} ${area}`;
  }

  return streetName;
};

export const convertArabicNumbersToEnglish = (number) => {
  return number?.toString()?.replace(/[٠-٩]/g, (d) => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));
};

export const constructOrder = (order, excelColumns, orderType) => {
  return {
    receiver: {
      fullName: order[excelColumns?.FULL_NAME]?.toString().trim(),
      phone: convertArabicNumbersToEnglish(
        order[excelColumns?.PHONE]?.toString()?.trim()
      ),
      secondPhone: convertArabicNumbersToEnglish(
        order[excelColumns?.SECOND_PHONE]?.toString()?.trim()
      )
    },
    dropOffAddress: {
      firstLine: getFirstLine({
        streetName: order[excelColumns?.STREET_NAME]?.toString(),
        area: order[excelColumns?.AREA]?.toString()
      }),
      secondLine: order[excelColumns?.SECOND_LINE]?.toString(),
      city: order[excelColumns?.CITY]?.toString()?.trim(),
      buildingNumber: order[excelColumns?.BUILDING]?.toString(),
      floor: order[excelColumns?.FLOOR]?.toString(),
      apartment: order[excelColumns?.ARAPRTMENT]?.toString(),
      isWorkAddress: getBooleanValue(order[excelColumns?.IS_WORK_ADDRESS])
    },
    specs: {
      packageType:
        orderType === SIGN_AND_RETURN
          ? 'Document'
          : order[excelColumns?.PACKAGE_TYPE]?.toString(),
      packageDetails: {
        itemsCount: order[excelColumns?.NUMBER_OF_ITEMS]?.toString(),
        description: order[excelColumns?.PACKAGE_DESCRIPTION]?.toString()
      }
    },
    returnSpecs: {
      packageDetails: {
        itemsCount: order[excelColumns?.RETURN_NUMBER_OF_ITEMS]?.toString(),
        description: order[excelColumns?.RETURN_PACKAGE_DESCRIPTION]?.toString()
      }
    },
    notes: order[excelColumns?.DELIVERY_NOTES]?.toString(),
    type: getOrderType(orderType)?.toString(),
    codAmount: getCODAmount(order[excelColumns?.CASH_AMOUNT]?.toString()),
    businessReference: order[excelColumns?.ORDER_REFERENCE]?.toString(),
    allowToOpenPackage: getBooleanValue(
      order[excelColumns?.ALLOW_OPENEING_PACKAGE]
    ),
    ...(isMultiPackageAvailable() &&
      ALLOWED_MULTI_PACKAGES_TYPES.includes(orderType) &&
      order[excelColumns?.MULTI_PACKAGES] >= MULTI_PACKAGES_COUNT.MIN && {
        multiPackages: order[excelColumns?.MULTI_PACKAGES]
      })
  };
};

export const constructInternationalOrder = (
  order,
  excelColumns,
  orderType,
  businessLocationId,
  defaultInternationalLocationId,
  selectedDestinationCountry
) => {
  const {
    dropOffAddress,
    notes,
    receiver,
    codAmount,
    businessReference,
    specs
  } = constructOrder(order, excelColumns, DELIVER);

  const internationalOrderSpecs = {
    ...specs,
    weight: order[excelColumns?.WEIGHT]?.toString(),
    packageType: PACKAGE_TYPES_OPTIONS[0].value
  };

  return {
    receiver: {
      ...receiver,
      phone: addCountryCodeNumber({
        phoneNumber: receiver?.phone,
        country: selectedDestinationCountry
      }),
      secondPhone: addCountryCodeNumber({
        phoneNumber: receiver?.secondPhone,
        country: selectedDestinationCountry
      })
    },
    dropOffAddress,
    notes,
    cod: codAmount || 0,
    specs: internationalOrderSpecs,
    businessReference,
    invoiceValue: order[excelColumns?.INVOICE_VALUE]?.toString(),
    businessLocationId,
    customVAT: {
      type: order[excelColumns?.CUSTOM_VAT] || CUSTOM_VAT_TYPES.DDU
    },
    ...INTERNATIONAL_RETURN_LOCATION_MAPPING(defaultInternationalLocationId)[
      order[excelColumns?.RETURN_LOCATION]
    ]
  };
};

export const constructFulfillmentOrder = (order, excelColumns, orderType) => {
  const { dropOffAddress, notes, receiver, codAmount } = constructOrder(
    order,
    excelColumns,
    orderType
  );

  const fxfOrder = {
    fulfillmentInfo: {
      items: [
        {
          bostaSKU: order[excelColumns?.PRODUCT_SKU]?.toString(),
          quantity: order[excelColumns?.PRODUCT_QUANTITY]?.toString() || 1,
          itemPrice: getCODAmount(order[excelColumns?.UNIT_PRICE]?.toString())
        }
      ]
    },
    type: ORDER_TYPES.fxf_send,
    businessReference: order[excelColumns?.ORDER_REFERENCE]?.toString(),
    allowToOpenPackage: getBooleanValue(
      order[excelColumns?.ALLOW_OPENEING_PACKAGE]
    )
  };
  return { dropOffAddress, notes, receiver, cod: codAmount, ...fxfOrder };
};

const isValidExcelSheet = ({ sheetColumns, businessMapping = [] }) => {
  const nonEmptyColumns = sheetColumns.filter((value) => value);

  const isValidMapping = businessMapping.every((column) => {
    return column.columnName === sheetColumns[column.index];
  });

  return isValidMapping && nonEmptyColumns.length === businessMapping.length;
};

// Bosta Excel Template Validation
const isBostaExcel = (workbook) => {
  const BOSTA_SHEETS = ['دليل المستخدم', 'User Guide', 'Add orders here'];

  return BOSTA_SHEETS.every((columnName) => workbook.sheet(columnName));
};

const isValidBostaExcel = ({ sheetColumns, uploadType }) => {
  const columns = [];
  const defaultBostaColumns = UPLOAD_TYPE_COLUMNS_MAPPING[uploadType].filter(
    ({ index }) => index !== undefined
  );

  sheetColumns.filter((value) =>
    value instanceof XlsxPopulate.RichText
      ? columns.push(value.text()?.replace(/[\n\r]|undefined/g, ''))
      : columns.push(value?.replace(/[\n\r]/g, ''))
  );

  const isValid = defaultBostaColumns.every((column) => {
    const sheetString = columns[column.index]
      ?.toLowerCase()
      ?.replace(/\s/g, '');

    return (
      sheetString?.includes(
        column.defaultName.toLowerCase()?.replace(/\s/g, '')
      ) ||
      sheetString?.includes(
        column.defaultNameAr.toLowerCase()?.replace(/\s/g, '')
      )
    );
  });

  const isEqualLength =
    columns.filter((col) => col).length === defaultBostaColumns.length;

  return isValid && isEqualLength;
};

export const getExcelColumnsFromMapping = (businessMapping) => {
  return businessMapping.reduce(
    (acc, column) => ({ ...acc, [column.name]: column.index }),
    {}
  );
};

const convertToBostaCities = ({
  allCitiesNames = [],
  allOrdersRows,
  excelColumns,
  allZonesNames = [],
  zones
}) => {
  const orders = allOrdersRows.map((order) => {
    const orderCity = order[excelColumns['CITY']]?.toString();

    // return if already matching bosta city.
    if (allCitiesNames.includes(orderCity)) {
      return order;
    }

    // check city similarity if ordercity is not empty.
    const citySimilarity =
      orderCity &&
      allCitiesNames.length &&
      stringSimilarity.findBestMatch(orderCity, allCitiesNames);

    // check if there is match with rating more than 30% and replace it.
    if (citySimilarity && citySimilarity.bestMatch.rating > 0.3) {
      order[excelColumns['CITY']] = citySimilarity.bestMatch.target;

      return order;
    }

    const zoneSimiliarity =
      orderCity &&
      allZonesNames.length &&
      stringSimilarity.findBestMatch(orderCity, allZonesNames);

    if (zoneSimiliarity && zoneSimiliarity.bestMatch.rating > 0.5) {
      order[excelColumns['CITY']] = zones.find(
        (zone) =>
          zone.name === zoneSimiliarity.bestMatch.target ||
          zone.nameAr === zoneSimiliarity.bestMatch.target
      ).city.name;

      return order;
    }

    return order;
  });

  return orders;
};

const sheetExceedsLimit = (workbook) => {
  const ordersSheet = workbook.sheet(0);
  const sheetOrders = ordersSheet.usedRange().value().slice(1);

  const allOrdersRows = sheetOrders
    .filter((row) => Object.keys(omitBy(row, (item) => !item)).length)
    .map((row) =>
      row.map((cell) =>
        cell instanceof XlsxPopulate.RichText
          ? cell.text().replace(/undefined/g, '')
          : cell
      )
    );

  return allOrdersRows?.length > 500;
};

export const getHeaderSize = (isBostaTemplate) => {
  return isBostaTemplate ? 2 : 1;
};

const sanitizeSheetColumns = (sheetColumns = []) => {
  return sheetColumns.map((column) =>
    column instanceof XlsxPopulate.RichText
      ? column.text().replace(/undefined/g, '')
      : column
  );
};

const NUMBER_OF_HEADERS_IN_EXCEL = 2;

export const convertExcelToJSON = (
  file,
  uploadType,
  businessMapping,
  orderType,
  allCitiesNames,
  allZonesNames,
  zones,
  defaultLocalLocation,
  defaultInternationalLocation,
  selectedDestinationCountry
) => {
  return new Promise((resolve, reject) => {
    XlsxPopulate.fromDataAsync(file).then((workbook) => {
      try {
        const sheet = workbook.sheet(0);
        const sheetColumns = sanitizeSheetColumns(sheet.usedRange().value()[0]);
        const isFulfillment = uploadType === FULFILLMENT;

        let excelColumns, noOfHeaders, isBostaTemplate;

        if (isBostaExcel(workbook)) {
          isBostaTemplate = true;
          const sheetColumns = workbook
            .sheet('Add orders here')
            .usedRange()
            .value()[1];

          if (isValidBostaExcel({ sheetColumns, uploadType })) {
            excelColumns = getExcelColumnsFromMapping(
              UPLOAD_TYPE_COLUMNS_MAPPING[uploadType]
            );

            noOfHeaders = NUMBER_OF_HEADERS_IN_EXCEL;
          } else {
            return reject(OLD_BOSTA_TEMPLATE);
          }
        } else if (isValidExcelSheet({ sheetColumns, businessMapping })) {
          excelColumns = getExcelColumnsFromMapping(businessMapping);
          noOfHeaders = 1;
        } else {
          if (sheetExceedsLimit(workbook)) {
            return reject(EXCEEDS_LIMIT);
          }
          return resolve({ uploadedFileColumns: sheetColumns });
        }

        const ordersSheet = workbook.sheet(
          isBostaTemplate ? 'Add orders here' : 0
        );

        const sheetOrders = ordersSheet.usedRange().value().slice(noOfHeaders);

        // GET ALL ORDERS (ROW ARRAY OF ARRAY OF ORDERS)
        const allOrdersRows = sheetOrders
          .filter((row) => Object.keys(omitBy(row, (item) => !item)).length)
          .map((row) =>
            row.map((cell) =>
              cell instanceof XlsxPopulate.RichText
                ? cell.text().replace(/undefined/g, '')
                : cell
            )
          );

        if (!allOrdersRows.length) {
          return reject(NO_ORDERS);
        } else if (allOrdersRows.length > 500) {
          return reject(EXCEEDS_LIMIT);
        }

        const orders = convertToBostaCities({
          allCitiesNames,
          allOrdersRows,
          excelColumns,
          allZonesNames,
          zones
        }).map((order) =>
          CONSTRUCT_ORDERS_MAPPING[uploadType](
            order,
            excelColumns,
            orderType,
            defaultLocalLocation,
            defaultInternationalLocation,
            selectedDestinationCountry
          )
        );

        sendSegment('E_SMART_UPLOAD_FILE', {
          'Excel Version': workbook?._appProperties?._node?.children
            .find((child) => child.name === 'AppVersion')
            ?.children.find((child) => child != null),
          'Operating System': workbook?._appProperties?._node?.children
            .find((child) => child.name === 'Application')
            ?.children.find((child) => child != null),
          'File Version': workbook?._coreProperties?._node?.children
            .find((child) => child.name === 'dc:title')
            ?.children.find((child) => child != null),
          'Order Type': isFulfillment ? FULFILLMENT : REGULAR,
          'No of Orders': orders?.length
        });

        resolve({ orders, allOrdersRows, isBostaTemplate });
      } catch (error) {
        reject(error);
      }
    });
  });
};

// Validation labels are representation to the field index in the mapped excel
const schema = (
  business,
  excelMapping,
  coveredAndUncoveredCitiesNames,
  maxCodLimit
) =>
  yup.array().of(
    yup.object().shape({
      receiver: yup.object().shape({
        fullName: yup
          .string()
          .label(excelMapping?.FULL_NAME)
          .required()
          .max(50)
          .test({
            name: 'Full Name Validation',
            test: function (value) {
              const { createError } = this;

              if (INCLUDES_DIGITS_REGEX.test(value)) {
                return createError();
              }

              return true;
            }
          }),
        phone: yup
          .string()
          .label(excelMapping?.PHONE)
          .test({
            name: 'phone validation',
            test: async function (value) {
              const { createError } = this;

              const validPhoneNumber = await validatePhoneNumber({
                value,
                required: true,
                phoneNumberRuleProps: {
                  message: '',
                  internationlNumbers: true
                }
              });

              if (!validPhoneNumber) {
                return createError();
              }

              return true;
            }
          }),
        secondPhone: yup
          .string()
          .label(excelMapping?.SECOND_PHONE)
          .notRequired()
          .test({
            name: 'phone validation',
            test: async function (value) {
              const { createError } = this;

              const validPhoneNumber = await validatePhoneNumber({
                value,
                phoneNumberRuleProps: {
                  message: '',
                  landlineNumbers: true
                }
              });

              if (!validPhoneNumber) {
                return createError();
              }

              return true;
            }
          })
      }),
      dropOffAddress: yup.object().shape({
        firstLine: yup
          .string()
          .label(excelMapping?.STREET_NAME)
          .required()
          .min(FIRST_LINE_MIN_LENGTH)
          .max(250),
        districtName: yup.string().label(excelMapping?.AREA),
        city: yup
          .string()
          .label(excelMapping?.CITY)
          .required()
          .test({
            name: 'city validation',
            test: function (value) {
              const { createError } = this;

              const { coveredCitiesNames, uncoveredCitiesNames } =
                coveredAndUncoveredCitiesNames;

              if (
                uncoveredCitiesNames.includes(value) ||
                !coveredCitiesNames.includes(value)
              ) {
                return createError();
              }

              return true;
            }
          }),
        isWorkAddress: yup
          .mixed()
          .label(excelMapping?.IS_WORK_ADDRESS)
          .test({
            name: 'is work address validation',
            test: function (value) {
              const { path, createError } = this;
              if (value == null) {
                return createError({
                  path,
                  message: 'Is work address should be empty, Yes or No.'
                });
              }

              return true;
            }
          })
      }),
      notes: yup.string().label(excelMapping?.DELIVERY_NOTES).max(200),
      type: yup
        .string()
        .nullable()
        .label(excelMapping?.TYPE)
        .test({
          name: 'Order type validation',
          test: function (value) {
            const { path, createError } = this;

            if (value === undefined) {
              return createError({
                path,
                message: 'Order type is required!'
              });
            } else if (value === null) {
              return createError({
                path,
                message:
                  'Order type should either be empty or one of the dropdown values'
              });
            }

            return true;
          }
        }),
      codAmount: yup
        .mixed()
        .label(excelMapping?.CASH_AMOUNT)
        .test({
          name: 'cash amount validation',
          test: function (value) {
            const { path, parent, createError } = this;

            const codAmount = convertArabicNumbersToEnglish(value) || null;
            const orderType = parent.type;
            const allowedToRefund = business.allowedToRefund;

            if (orderType === ORDER_TYPES.deliver) {
              if (codAmount < 0 || isNaN(codAmount)) {
                return createError({
                  path,
                  message: 'deliver_negative_string'
                });
              }
            } else if (orderType === ORDER_TYPES.exchange) {
              if (allowedToRefund && isNaN(codAmount)) {
                return createError({
                  path,
                  message: 'exchange_allowed_refund_string'
                });
              } else if (
                !allowedToRefund &&
                (codAmount < 0 || isNaN(codAmount))
              ) {
                return createError({
                  path,
                  message: 'exchange_not_allowed_refund_negative_string'
                });
              }
            } else if (orderType === ORDER_TYPES.return) {
              if (allowedToRefund && isNaN(codAmount)) {
                return createError({
                  path,
                  message: 'return_allowed_refund_string'
                });
              } else if (allowedToRefund && codAmount > 0) {
                return createError();
              } else if (
                !allowedToRefund &&
                (codAmount > 0 || codAmount < 0 || isNaN(codAmount))
              ) {
                return createError({
                  path,
                  message: 'return_not_allowed_refund_positive_negative_string'
                });
              }
            } else if (orderType === ORDER_TYPES['cash_collection']) {
              if (
                allowedToRefund &&
                (codAmount == null || codAmount === 0 || isNaN(codAmount))
              ) {
                return createError({
                  path,
                  message: 'cash_collection_allowed_refund_empty_zero_string'
                });
              } else if (
                !allowedToRefund &&
                (codAmount == null ||
                  codAmount === 0 ||
                  codAmount < 0 ||
                  isNaN(codAmount))
              ) {
                return createError({
                  path,
                  message:
                    'cash_collection_not_allowed_refund_empty_zero_negative_string'
                });
              }
            }

            if (codAmount > maxCodLimit) {
              return createError({
                path,
                message: 'cod_greater_than_30000'
              });
            } else if (allowedToRefund && codAmount < REFUND_MAX_AMOUNT) {
              return createError();
            }

            return true;
          }
        }),
      specs: yup.object().shape({
        packageType: yup
          .mixed()
          .label(excelMapping?.PACKAGE_TYPE)
          .test({
            name: 'package type validation',
            test: function (value) {
              const { path, createError } = this;
              if (
                value != null &&
                !PACKAGE_TYPES_OPTIONS.map((type) =>
                  type.value.toLowerCase()
                ).includes(value?.toString()?.trim()?.toLowerCase())
              ) {
                return createError({
                  path,
                  message:
                    'Package type should either be empty or one of the dropdown values'
                });
              }

              return true;
            }
          }),
        packageDetails: yup.object().shape({
          itemsCount: yup.string().label(excelMapping?.NUMBER_OF_ITEMS).max(4),
          description: yup
            .string()
            .label(excelMapping?.PACKAGE_DESCRIPTION)
            .max(500)
        })
      }),
      businessReference: yup
        .string()
        .label(excelMapping?.ORDER_REFERENCE)
        .max(100, 'Order Reference should be less than 100 characters'),
      allowToOpenPackage: yup
        .mixed()
        .label(excelMapping?.ALLOW_OPENEING_PACKAGE)
        .test({
          name: 'allow open packages validation',
          test: function (value) {
            const { path, createError } = this;
            if (value == null) {
              return createError({
                path,
                message: 'Allow open packages should be empty, Yes or No.'
              });
            }

            return true;
          }
        }),
      returnSpecs: yup.object().shape({
        packageDetails: yup.object().shape({
          itemsCount: yup
            .string()
            .label(excelMapping?.RETURN_NUMBER_OF_ITEMS)
            .max(4),
          description: yup
            .string()
            .label(excelMapping?.RETURN_PACKAGE_DESCRIPTION)
            .max(500)
        })
      }),
      multiPackages: yup
        .mixed()
        .label(excelMapping?.MULTI_PACKAGES)
        .test({
          test: function (value) {
            const { createError } = this;
            const { MIN, MAX } = MULTI_PACKAGES_COUNT;

            if ((value && value < MIN) || value > MAX) {
              return createError();
            }

            return true;
          }
        })
    })
  );

const internationalOrderSchema = ({
  excelMapping,
  coveredAndUncoveredCitiesNames,
  maxCodLimit,
  selectedDestinationCountry
}) =>
  yup.array().of(
    yup.object().shape({
      receiver: yup.object().shape({
        fullName: yup
          .string()
          .label(excelMapping?.FULL_NAME)
          .required()
          .max(50)
          .test({
            test: function (value) {
              const { createError } = this;

              if (INCLUDES_DIGITS_REGEX.test(value)) {
                return createError();
              }

              return true;
            }
          }),
        phone: yup
          .string()
          .label(excelMapping?.PHONE)
          .test({
            test: async function (value) {
              const { createError } = this;

              const validPhoneNumber = await validatePhoneNumber({
                value,
                required: true,
                phoneNumberRuleProps: {
                  message: '',
                  internationlNumbers: false,
                  country: selectedDestinationCountry
                }
              });

              if (!validPhoneNumber) {
                return createError();
              }

              return true;
            }
          }),
        secondPhone: yup
          .string()
          .label(excelMapping?.SECOND_PHONE)
          .notRequired()
          .test({
            test: async function (value) {
              const { createError } = this;

              const validPhoneNumber = await validatePhoneNumber({
                value,
                phoneNumberRuleProps: {
                  message: '',
                  landlineNumbers: true,
                  country: selectedDestinationCountry
                }
              });

              if (!validPhoneNumber) {
                return createError();
              }

              return true;
            }
          })
      }),
      businessReference: yup
        .string()
        .label(excelMapping?.ORDER_REFERENCE)
        .max(100),
      specs: yup.object().shape({
        packageType: yup
          .mixed()
          .label(excelMapping?.PACKAGE_TYPE)
          .test({
            test: function (value) {
              const { createError } = this;
              if (
                value != null &&
                !PACKAGE_TYPES_OPTIONS.map((type) =>
                  type.value.toLowerCase()
                ).includes(value?.toString()?.trim()?.toLowerCase())
              ) {
                return createError();
              }

              return true;
            }
          }),
        packageDetails: yup.object().shape({
          itemsCount: yup.string().label(excelMapping?.NUMBER_OF_ITEMS).max(4),
          description: yup
            .string()
            .label(excelMapping?.PACKAGE_DESCRIPTION)
            .max(500)
        }),
        weight: yup
          .string()
          .label(excelMapping?.WEIGHT)
          .test({
            test: function (value) {
              const { createError } = this;

              if (!regexFractionsNumber.test(value)) {
                return createError();
              }

              return true;
            }
          })
      }),
      dropOffAddress: yup.object().shape({
        firstLine: yup
          .string()
          .label(excelMapping?.STREET_NAME)
          .required()
          .max(250),
        districtName: yup.string().label(excelMapping?.AREA),
        city: yup
          .string()
          .label(excelMapping?.CITY)
          .required()
          .test({
            test: function (value) {
              const { createError } = this;

              const { coveredCitiesNames, uncoveredCitiesNames } =
                coveredAndUncoveredCitiesNames;

              if (
                uncoveredCitiesNames.includes(value) ||
                !coveredCitiesNames.includes(value)
              ) {
                return createError();
              }

              return true;
            }
          }),
        isWorkAddress: yup
          .mixed()
          .label(excelMapping?.IS_WORK_ADDRESS)
          .test({
            test: function (value) {
              const { createError } = this;
              if (value == null) {
                return createError();
              }

              return true;
            }
          })
      }),
      notes: yup.string().label(excelMapping?.DELIVERY_NOTES).max(200),
      invoiceValue: yup
        .string()
        .label(excelMapping?.INVOICE_VALUE)
        .test({
          test: function (value) {
            const { createError } = this;

            if (!regexFractionsNumber.test(value)) {
              return createError();
            }

            return true;
          }
        }),
      codAmount: yup
        .mixed()
        .label(excelMapping?.CASH_AMOUNT)
        .test({
          test: function (value) {
            const { createError } = this;

            const codAmount = convertArabicNumbersToEnglish(value) || null;

            if (codAmount < 0 || isNaN(codAmount) || codAmount > maxCodLimit) {
              return createError();
            }

            return true;
          }
        }),
      customVAT: yup.object().shape({
        type: yup
          .mixed()
          .label(excelMapping?.CUSTOM_VAT)
          .required()
          .test({
            test: function (value) {
              const { createError } = this;
              if (
                value != null &&
                !Object.values(CUSTOM_VAT_TYPES_OPTIONS)
                  .map((type) => type.value.toLowerCase())
                  .includes(value?.toString()?.trim()?.toLowerCase())
              ) {
                return createError();
              }

              return true;
            }
          })
      }),
      returnAddress: yup
        .mixed()
        .label(excelMapping?.RETURN_LOCATION)
        .test({
          test: function (value) {
            const { createError, parent } = this;
            const {
              returnToFulfillmentHub,
              terminateIfFailed,
              returnToDefaultLocation
            } = parent || {};

            if (
              !value &&
              !returnToFulfillmentHub &&
              !terminateIfFailed &&
              !returnToDefaultLocation
            ) {
              return createError();
            }

            return true;
          }
        }),
      returnToFulfillmentHub: yup
        .mixed()
        .label(excelMapping?.RETURN_LOCATION)
        .test({
          test: function (value) {
            const { createError, parent } = this;
            const {
              returnAddress,
              terminateIfFailed,
              returnToDefaultLocation
            } = parent || {};

            if (
              !value &&
              !returnAddress &&
              !terminateIfFailed &&
              !returnToDefaultLocation
            ) {
              return createError();
            }

            return true;
          }
        }),
      terminateIfFailed: yup
        .mixed()
        .nullable()
        .label(excelMapping?.RETURN_LOCATION)
        .test({
          test: function (value) {
            const { createError, parent } = this;
            const {
              returnAddress,
              returnToFulfillmentHub,
              returnToDefaultLocation
            } = parent || {};

            if (
              !value &&
              !returnAddress &&
              !returnToFulfillmentHub &&
              !returnToDefaultLocation
            ) {
              return createError();
            }

            return true;
          }
        }),
      returnToDefaultLocation: yup
        .mixed()
        .nullable()
        .label(excelMapping?.RETURN_LOCATION)
        .test({
          test: function (value) {
            const { createError, parent } = this;
            const { returnAddress, returnToFulfillmentHub, terminateIfFailed } =
              parent || {};

            if (
              !value &&
              !returnAddress &&
              !returnToFulfillmentHub &&
              !terminateIfFailed
            ) {
              return createError();
            }

            return true;
          }
        })
    })
  );

const fulfillmentSchema = ({
  excelMapping,
  coveredAndUncoveredCitiesNames,
  fulfillmentProductAvailability = []
}) =>
  yup.array().of(
    yup.object().shape({
      receiver: yup.object().shape({
        fullName: yup
          .string()
          .label(excelMapping?.FULL_NAME)
          .required()
          .max(50)
          .test({
            name: 'Full Name Validation',
            test: function (value) {
              const { createError } = this;

              if (INCLUDES_DIGITS_REGEX.test(value)) {
                return createError();
              }

              return true;
            }
          }),
        phone: yup
          .string()
          .label(excelMapping?.PHONE)
          .test({
            name: 'phone validation',
            test: async function (value) {
              const { createError } = this;

              const validPhoneNumber = await validatePhoneNumber({
                value,
                required: true,
                phoneNumberRuleProps: {
                  message: '',
                  internationlNumbers: true
                }
              });

              if (!validPhoneNumber) {
                return createError();
              }

              return true;
            }
          }),
        secondPhone: yup
          .string()
          .label(excelMapping?.SECOND_PHONE)
          .notRequired()
          .test({
            name: 'phone validation',
            test: async function (value) {
              const { createError } = this;

              const validPhoneNumber = await validatePhoneNumber({
                value,
                phoneNumberRuleProps: {
                  message: '',
                  landlineNumbers: true
                }
              });

              if (!validPhoneNumber) {
                return createError();
              }

              return true;
            }
          })
      }),
      fulfillmentInfo: yup.object().shape({
        items: yup.array().of(
          yup.object().shape({
            bostaSKU: yup.string().label(excelMapping?.PRODUCT_SKU).required(),
            quantity: yup
              .mixed()
              .label(excelMapping?.PRODUCT_QUANTITY)
              .test({
                test: function (value) {
                  const { parent, createError, path } = this;

                  const orderIndex = parseInt(
                    path.split('.')[0].replace(/[[\]']+/g, '')
                  );

                  const productAvailabilityError =
                    fulfillmentProductAvailability.find(
                      (quantityError) =>
                        quantityError?.bostaSKU === parent?.bostaSKU &&
                        quantityError?.relatedToBusiness &&
                        quantityError?.index === orderIndex
                    );

                  if (
                    productAvailabilityError &&
                    value > productAvailabilityError?.availableQty
                  ) {
                    return createError();
                  }

                  return true;
                }
              }),
            itemPrice: yup
              .mixed()
              .label(excelMapping?.UNIT_PRICE)
              .test({
                name: 'cash amount validation',
                test: function (value) {
                  const { path, createError } = this;

                  const codAmount =
                    convertArabicNumbersToEnglish(value) || null;
                  const COD_MAX_LIMIT = 30000;

                  if (codAmount > COD_MAX_LIMIT) {
                    return createError({
                      path,
                      message: 'cod_greater_than_30000'
                    });
                  }

                  return true;
                }
              })
          })
        )
      }),
      dropOffAddress: yup.object().shape({
        firstLine: yup
          .string()
          .label(excelMapping?.STREET_NAME)
          .required()
          .min(FIRST_LINE_MIN_LENGTH)
          .max(250),
        districtName: yup.string().label(excelMapping?.AREA),
        city: yup
          .string()
          .label(excelMapping?.CITY)
          .required()
          .test({
            name: 'city validation',
            test: function (value) {
              const { createError } = this;

              const { coveredCitiesNames, uncoveredCitiesNames } =
                coveredAndUncoveredCitiesNames;

              if (
                uncoveredCitiesNames.includes(value) ||
                !coveredCitiesNames.includes(value)
              ) {
                return createError();
              }

              return true;
            }
          }),
        isWorkAddress: yup
          .mixed()
          .label(excelMapping?.IS_WORK_ADDRESS)
          .test({
            name: 'is work address validation',
            test: function (value) {
              const { path, createError } = this;
              if (value == null) {
                return createError({
                  path,
                  message: 'Is work address should be empty, Yes or No.'
                });
              }

              return true;
            }
          })
      }),
      notes: yup.string().label(excelMapping?.DELIVERY_NOTES).max(200),
      businessReference: yup
        .string()
        .label(excelMapping?.ORDER_REFERENCE)
        .max(100),
      allowToOpenPackage: yup
        .mixed()
        .label(excelMapping?.ALLOW_OPENEING_PACKAGE)
        .test({
          name: 'allow open packages validation',
          test: function (value) {
            const { path, createError } = this;
            if (value == null) {
              return createError({
                path,
                message: 'Allow open packages should be empty, Yes or No.'
              });
            }

            return true;
          }
        }),
      ...(isSaudi() && {
        cod: yup.mixed().label(excelMapping?.CASH_AMOUNT).required()
      })
    })
  );

const groupErrorsByRow = (errors) => {
  // Here we group orders validation errors by row index.
  // we return an object where the key represent the index of the row.
  // and the value is an array of indexes representing the mapped columns
  return errors.reduce((errorsObject, error) => {
    const errorIndex = Number(error.path.split('.')[0].slice(1, -1));
    errorsObject[errorIndex] = errorsObject[errorIndex] || [];
    errorsObject[errorIndex].push(error.params.label);
    return errorsObject;
  }, {});
};

export const validateOrders = ({
  orders,
  business,
  excelMapping,
  coveredAndUncoveredCitiesNames,
  uploadType,
  fulfillmentProductAvailability,
  maxCodLimit = COD_MAX_LIMIT,
  selectedDestinationCountry
}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const isFulfillment = uploadType === FULFILLMENT_ORDERS;
      const isInternationalOrder = uploadType === INTERNATIONAL_ORDERS;

      if (isInternationalOrder) {
        await internationalOrderSchema({
          excelMapping,
          coveredAndUncoveredCitiesNames,
          maxCodLimit,
          selectedDestinationCountry
        }).validate(orders, {
          abortEarly: false
        });
      } else if (isFulfillment) {
        await fulfillmentSchema({
          excelMapping,
          coveredAndUncoveredCitiesNames,
          fulfillmentProductAvailability
        }).validate(orders, {
          abortEarly: false
        });
      } else {
        await schema(
          business,
          excelMapping,
          coveredAndUncoveredCitiesNames,
          maxCodLimit
        ).validate(orders, {
          abortEarly: false
        });
      }

      resolve({ isValid: true });
    } catch (error) {
      resolve({ isValid: false, errors: groupErrorsByRow(error.inner) });
    }
  });
};

export const exportDeletedOrders = ({
  file,
  allOrders,
  deletedOrders,
  headerSize,
  isBostaTemplate,
  fileName = 'download'
}) => {
  return new Promise((resolve, reject) => {
    XlsxPopulate.fromDataAsync(file).then((workbook) => {
      const sheet = workbook.sheet(isBostaTemplate ? 'Add orders here' : 0);

      // Delete All Orders from current Excel File
      sheet._rows.splice(headerSize + 1, allOrders.length);
      sheet._rows.forEach((row, index) => {
        row._node.attributes.r = index;
      });

      // Orders to fill the sheet with
      const ordersToAddToExcel = allOrders.filter((_, index) =>
        deletedOrders.includes(index)
      );

      // Fill the sheet with deleted orders.
      sheet.cell(`A${headerSize + 1}`).value(ordersToAddToExcel);

      // Download Excel
      workbook.outputAsync().then((blob) => {
        const todaysDate = dayjs().format('DD-MM-YYYY');
        downloadAsXlsx(blob, `${fileName}-${todaysDate}`);
        return resolve(true);
      });
    });
  });
};

const codValidator = ({ value, businessInfo, orderType, maxLimit }) => {
  const allowedToRefund = businessInfo.allowedToRefund;

  const codAmount = convertArabicNumbersToEnglish(value);

  if (orderType === SEND) {
    if (codAmount < 0 || isNaN(codAmount)) {
      return Promise.reject(
        fmt({
          id: 'smart_massupload.error_validation.error_messages.invalid_cod_deliver'
        })
      );
    }
  } else if (orderType === EXCHANGE) {
    if (allowedToRefund && isNaN(codAmount)) {
      return Promise.reject(
        fmt({
          id: 'smart_massupload.error_validation.error_messages.invalid_cod_exchange_allowed'
        })
      );
    } else if (!allowedToRefund && (codAmount < 0 || isNaN(codAmount))) {
      return Promise.reject(
        fmt({
          id: 'smart_massupload.error_validation.error_messages.invalid_cod_exchange_not_allowed'
        })
      );
    }
  } else if (orderType === RETURN) {
    if (allowedToRefund && isNaN(codAmount)) {
      return Promise.reject(
        fmt({
          id: 'smart_massupload.error_validation.error_messages.invalid_cod_crp_allowed'
        })
      );
    } else if (allowedToRefund && codAmount > 0) {
      return Promise.reject(
        fmt({
          id: 'smart_massupload.error_validation.error_messages.invalid_cod_crp_postive_amount'
        })
      );
    } else if (
      !allowedToRefund &&
      (codAmount > 0 || codAmount < 0 || isNaN(codAmount))
    ) {
      return Promise.reject(
        fmt({
          id: 'smart_massupload.error_validation.error_messages.invalid_cod_crp_not_allowed'
        })
      );
    }
  } else if (orderType === CASH_COLLECTION) {
    if (allowedToRefund && (!codAmount || isNaN(codAmount))) {
      return Promise.reject(
        fmt({
          id: 'smart_massupload.error_validation.error_messages.invalid_cod_cash_collection_allowed'
        })
      );
    } else if (
      !allowedToRefund &&
      (!codAmount || codAmount < 0 || isNaN(codAmount))
    ) {
      return Promise.reject(
        fmt({
          id: 'smart_massupload.error_validation.error_messages.invalid_cod_cash_collection_not_allowed'
        })
      );
    }
  }

  if (codAmount > maxLimit) {
    return Promise.reject(
      `${fmt({
        id: `new_order.order_details.cod_greater_than_30000`
      })}${fmt(
        {
          id: COUNTRY_CURRENCY_MODIFIED
        },
        {
          cod: formatNumbersBasedOnLanguage(maxLimit)
        }
      )}`
    );
  } else if (allowedToRefund && codAmount < REFUND_MAX_AMOUNT) {
    return Promise.reject(
      fmt(
        {
          id: 'orders.mass_upload.error_table.errors.refund_less_than_max'
        },
        {
          amount: getUserCurrency(REFUND_MAX_AMOUNT).localized
        }
      )
    );
  }

  return Promise.resolve();
};

export const codRule = ({
  businessInfo,
  orderType,
  maxLimit = COD_MAX_LIMIT
}) => ({
  validator: (_, value) =>
    codValidator({ value, businessInfo, orderType, maxLimit })
});

const oneOfValidator = ({
  value,
  values,
  message,
  excludedValues,
  excludedValuesMessage
}) => {
  if (!value) {
    return Promise.resolve();
  }

  if (excludedValues && excludedValues.includes(value)) {
    return Promise.reject(excludedValuesMessage);
  }

  if (!values.includes(value)) {
    return Promise.reject(message);
  }

  return Promise.resolve();
};

export const oneOfRule = ({
  values,
  message,
  excludedValues,
  excludedValuesMessage
}) => ({
  validator: (_, value) =>
    oneOfValidator({
      value,
      values,
      message,
      excludedValues,
      excludedValuesMessage
    })
});

const productQuantityValidator = ({ value, productsQuantity, productSKU }) => {
  if (value < 1) {
    return Promise.reject(
      fmt({
        id: 'smart_massupload.error_validation.error_messages.product_quantity_less_than_1'
      })
    );
  }

  if (!value || value <= productsQuantity[productSKU]) {
    return Promise.resolve();
  }

  return Promise.reject(
    fmt({
      id: 'smart_massupload.error_validation.error_messages.insufficient_quantity'
    })
  );
};

export const productQuantityRule = ({ productsQuantity, productSKU }) => ({
  validator: (_, value) =>
    productQuantityValidator({ value, productsQuantity, productSKU })
});

const unitPriceValidator = ({ value, message }) => {
  const unitPrice = convertArabicNumbersToEnglish(value);

  if (!unitPrice || unitPrice <= COD_MAX_LIMIT) {
    return Promise.resolve();
  }

  return Promise.reject(message);
};

export const unitPriceRule = ({ message }) => ({
  validator: (_, value) => unitPriceValidator({ value, message })
});

export const handleLearnHowToFillItClick = () => {
  window.open(SMART_MASSUPLOAD_ARTICLE_LINK, '_blank');
};

export const checkFulfillmentProductsAvailability = async (orders = []) => {
  if (!orders.length) {
    return;
  }

  try {
    const payload = {
      products: orders.map((order) => order.fulfillmentInfo.items[0])
    };

    const data = await checkProductAvailability(payload);

    return data;
  } catch (error) {
    notify({ msg: error.message, error });
  }
};
