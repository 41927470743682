import { getAWBLanguage } from 'constants/countries/countries-mapping';
import { bostaHttp } from 'http.js';

export const getShipmentDetails = (id, payload) => {
  return bostaHttp.get(`/deliveries/business/${id}`, payload);
};

export const deleteShipment = (id) => {
  return bostaHttp.delete(`/deliveries/business/${id}/terminate`);
};

export const deleteMultiple = (payload) => {
  return bostaHttp.delete('deliveries/business/deleteMany', payload);
};

export const printAirwaybill = (payload) => {
  const data = {
    lang: getAWBLanguage(),
    ...payload
  };

  return bostaHttp.get(`/deliveries/business/awb`, data);
};

export const createOrder = (payload) => {
  return bostaHttp.post(`/deliveries`, payload);
};

export const editOrder = (id, payload) => {
  return bostaHttp.put(`/deliveries/business/${id}`, payload);
};

export const createMassOrders = (payload) => {
  return bostaHttp.post(`/deliveries/bulk`, payload);
};

export const printMassAirwayBills = (payload) => {
  const data = {
    lang: getAWBLanguage(),
    ...payload
  };
  return bostaHttp.post(`/deliveries/mass-awb`, data);
};

export const listAllDeliveries = (payload) => {
  return bostaHttp.post(`/deliveries/search`, payload);
};

export const exportCSVDeliveries = (payload) => {
  return bostaHttp.post(`/deliveries/exportxlsx`, payload);
};

export const takePendingActions = (id, payload) => {
  return bostaHttp.put(`/deliveries/${id}/take-pending-actions`, payload);
};

export const getConsigneeRate = (phone) => {
  return bostaHttp.get(`/deliveries/consignee-rate/${phone}`);
};

export const rescheduleOrder = (id, payload) => {
  return bostaHttp.put(`/deliveries/business/${id}/reschedule`, payload);
};

export const updateAWBPrintingOption = (businessId, payload) => {
  return bostaHttp.put(`/businesses/${businessId}/update-awb-options`, payload);
};

export const checkIfBulkyDeliveriesExist = () => {
  return bostaHttp.get(`/deliveries/bulky`);
};

export const getMassUploadMapping = () => {
  return bostaHttp.get('/businesses/mass-upload-mapping');
};

export const setMassUploadMapping = (payload) => {
  return bostaHttp.put('/businesses/mass-upload-mapping', payload);
};

export const getShippingFeesEstimate = (payload) => {
  return bostaHttp.get('/pricing/shipment/calculator', payload);
};

export const getProofOfReturn = (id) => {
  return bostaHttp.get(`/deliveries/proof-of-return?deliveryId=${id}`);
};

export const getTicketLogs = (trackingNumber) => {
  return bostaHttp.get(`/tickets/${trackingNumber}/tickets`);
};

export const cancelOrder = (orderId) => {
  return bostaHttp.put(`/deliveries/business/${orderId}/cancel`);
};

export const getDeliveryProducts = (id) => {
  return bostaHttp.get(`/deliveries/${id}/productsDetails`);
};

export const deleteFlyersOrder = (id) => {
  return bostaHttp.delete(
    `/deliveries/business/${id}/terminate?creationSrc=FLYERS_DASHBOARD`
  );
};

export const sendStarInstruction = ({ deliveryId, payload }) => {
  return bostaHttp.put(
    `/deliveries/business/${deliveryId}/star-instruction`,
    payload
  );
};

export const createInternationalOrder = (payload) => {
  return bostaHttp.post('/international-order', payload);
};

export const getBarcodeScanShortLink = (payload) => {
  return bostaHttp.post('/barcode/shortLink', payload);
};

export const getDeliveryEvents = (payload) => {
  return bostaHttp.get('/deliveryEvents', payload);
};

export const getTrackerBarInfo = () => {
  return bostaHttp.get('/businesses/tracker-bar-info');
};

export const getComparePricesInfo = (payload) => {
  return bostaHttp.get(`pricing/moving-between-tiers-calculator`, payload);
};

export const getSignedUrl = (payload) => {
  return bostaHttp.get(`deliveries/signedUrl`, payload);
};
