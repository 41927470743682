import { bostaHttp, sllrHttp } from 'http.js';

export const submitStoreLink = (payload) => {
  return bostaHttp.post(`/businesses/sllr/submitStoreLink`, payload);
};

export const getStoreLinkSuggestions = (payload) => {
  return bostaHttp.post(`/sllr/suggestedLinks`, payload);
};

export const updateBusinessStoreInfo = (data, id) => {
  return bostaHttp.put(`/businesses/${id}`, data);
};

export const getAllProducts = (payload) => {
  return bostaHttp.get('/products', payload);
};

export const getProductsWithVariants = (payload) => {
  return bostaHttp.post('/products/list', payload);
};

export const getProductData = (id) => {
  return bostaHttp.get(`/products/${id}`);
};

export const getProductDetails = (id) => {
  return bostaHttp.get(`/products/overview/${id}`);
};

export const getProductAvailability = (id) => {
  return bostaHttp.get(`/products/${id}/availability`);
};

export const updateProduct = (id, payload) => {
  return bostaHttp.put(`/products/${id}`, payload);
};

export const activateProduct = (id, payload) => {
  return bostaHttp.put(`/products/activate/${id}`, payload);
};

export const deleteProduct = (id) => {
  return bostaHttp.delete(`/products/${id}`);
};

export const deleteManyProducts = (payload) => {
  return bostaHttp.delete('/products/deleteMany', payload);
};

export const getVariants = (id) => {
  return bostaHttp.get(`/products/${id}/variancesOverview`);
};

export const activateVariant = (variantId, payload) => {
  return bostaHttp.put(`/products/variance-activate/${variantId}`, payload);
};

export const createMultipleProducts = (payload) => {
  return bostaHttp.post(`/products/bulk`, payload);
};

export const multiActivate = (payload) => {
  return bostaHttp.put(`/products/activate`, payload);
};

export const createNewProduct = (payload) => {
  return bostaHttp.post('/products', payload);
};

export const getProductCategories = ({ businessId, usedOnly = false }) => {
  return bostaHttp.get(
    `/productCategories/business/${businessId}${usedOnly && '/usedCategories'}`
  );
};

export const createProductCategory = (payload) => {
  return bostaHttp.post(`/productCategories`, payload);
};

export const getSllrAnalytics = (payload) => {
  return sllrHttp.get('/analytics', payload);
};

export const reorderProduct = (id, payload) => {
  return bostaHttp.put(`/products/product-order/${id}`, payload);
};
